// Change menu bar background colors
#nav {
  background-color: #FFFFFF;
  background-image: none;
}
#header {
  background-image: none;
  padding-top: 2em;
}
#header h1 {
  font-size: 20pt;
  padding-bottom: 0;
}

#banner header {
  background: rgba(27, 27, 27, 0.75)
}

// Change menu bar text colors
#nav>ul>li:hover a {
  color: #000000;
}

#nav>ul>li a {
 color: #676767
}

#nav>ul>li.active a {
  color: #000000;
}

#nav>ul>li.current a {
  color: #000000;
  // font-weight: 500;
  // text-decoration: underline;
}

// Format course details outline in sidebar
section.course-overview,
section.upcoming-courses {
  h2 {
    margin-bottom: 0.15em;
  }

  h3 {
    margin-top: 7pt;
    margin-bottom: 0pt;
  }
  
  h4 {
    margin-top: 7pt;
    margin-bottom: 0pt;
  }

  p, ul {
    font-size: 13pt;
  }

  p {
    margin-bottom: 1em;
  }

  ul {
    margin-bottom: 1em;
  }
//  a.button {
//    margin-top: 1em;
//    margin-bottom: 1em;
//  }
}

section.upcoming-courses {
  h2 {
    margin-top: 1em;
  }
}

h5 {
  margin-top: 0;
  margin-bottom: 0;
}

// Change "Next Course:" text color to be more subtle
time strong.next-course {
  color: #6a6a6a;
}

// Remove dropshadow from buttons and icons
input[type="submit"],
input[type="reset"],
input[type="button"],
button,
.button {
  background-image: none;
}

.icon.major {
  background-image: none;
  // background-color: red;
}

.button.light {
  background-color: #FFF;
  //color: #37c0fb;
  color: #EE0000;
  border-style: solid;
  border-width: 1px;
  // border-color: #37c0fb;
  border-color: #EE0000;
}
.button.light:hover {
  //color: darken(#37c0fb, 20) !important;
  color: darken(#EE0000, 20) !important;
  // border-color: darken(#37c0fb, 20) !important;
  border-color: darken(#EE0000, 20) !important;
}

.contact-page ul {
  list-style: none;
  padding-left: 0.2em;
}

.responsive-iframe-container {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;
}

.responsive-iframe-container iframe,
.vresponsive-iframe-container object,
.vresponsive-iframe-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

#footer {
  padding-bottom: 15px;

  .container {
    margin-bottom: 1em;
  }
  .copyright {
    margin-top: 0;
  }
  .menu {
    margin-bottom: 0;
  }

  a.button {
    color: #fff;
  }
  ul {
    list-style: none;
    padding-left: 0;
  }
  li {
    padding-left: 0;
    line-height: 1.5em;
  }
  h3 {
    margin-bottom: 0.5em;
  }
}

#main #e404 {
  text-align: center;
  h1.xxl {
    font-size: 100pt;
    line-height: 1;
    margin-bottom: 0;
    color: #bebebe;
  }
  img {
    max-width: 300px;
    width: 75%;
  }
}

// Mobile
@include breakpoint('<=mobile') {
  #footer {
      p, h1, h2, h3, h4, h5, ul {
        text-align: center;
      }
    }
}

// Mobile portrait
@include breakpoint('<=mobilep') {
  #footer h3 {
    font-size: 1.4em;
  }
}



// Automatically add colon after all h4 tags
// h4:after {
//   content: ":";
// }